import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import laundryLine from "../../Assets/laundry-line.png";
import Tilt from "react-parallax-tilt";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineGlobal } from "react-icons/ai";

import { HiOutlineMail } from "react-icons/hi";

function Contact() {
  return (
    <Container fluid className="contact-section">
      <Container>
      <Row style={{ textAlign: "center" }}>
        <h1>Laundry Detergent Sheets Manufacturer</h1>
        <h2 className="contact-title">
          Contact Us
          <p>Our business starts here</p>
        </h2>
      </Row>
      <Row>
        <Col md={4} style={{ paddingBottom: 20 }}>
          <Tilt>
            <img
              src={laundryLine}
              alt="laundry detergent strips manufacturer"
              className="img-fluid"
            />
          </Tilt>
        </Col>
        <Col md={8}>
          <h1>
            Have questions? Feel free to get in touch with us directly by
            sending a message.
          </h1>
          <div className="contact-text">
            <HiOutlineMail className="contact-icon" /> <strong>Email:</strong>
            <div>
              Drop us a line at{" "}
              <a href="mailto:hello@kleanleaf.us" className="contact-email">
                hello@kleanleaf.us
              </a>{" "}
              or <a href="mailto:kleanleaf@gmail.com" className="contact-email">
              kleanleaf@gmail.com
              </a>{" "}
                and our friendly team will get back to you as fast as
              possible.
            </div>
          </div>
          <div className="contact-text">
            <FiPhoneCall className="contact-icon" /> <strong>Phone:</strong>{" "}
            <div>
              Need to chat? Give us a call at{" "}
              <a href="tel:614-906-5129" className="contact-number">
                614.906.5129
              </a>
            </div>
          </div>
          <div className="contact-text">
            <AiOutlineGlobal className="contact-icon" />{" "}
            <strong>Website: </strong>
            <div>
              Visit our website for more information: www.kleanleaf.us{" "}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Container>
  );
}

export default Contact;
