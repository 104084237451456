import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import Rellax from "rellax";
import { AiOutlineDownload } from "react-icons/ai";
import pdf from "../../Assets/klean-leaf-profile.pdf";
import Techstack from "./Techstack";
import claw from "../../Assets/factory_1.png";
import boy from "../../Assets/factory_3.png";
import stuffy from "../../Assets/factory_2.png";
import bubbles from "../../Assets/bubbles.png";
import basket from "../../Assets/basket-bg.png";
import hanger from "../../Assets/clothes-hanger.png";
import kleanleaf from "../../Assets/kleanleaf.png";
function About() {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  // Initialize Rellax (parallax effect)
  useEffect(() => {
    // Check if elements with the class 'rellax' exist before initializing Rellax
    const elements = document.querySelectorAll('.rellax');
    if (elements.length > 0) {
      const rellax = new Rellax('.rellax');
  
      // Cleanup function to destroy the instance if it exists
      return () => {
        if (rellax && typeof rellax.destroy === 'function') {
          rellax.destroy();
        }
      };
    }
  }, []);

  return (
    <Container fluid className="about-section" id="about">
      <Helmet>
        <title>About Us - Klean Leaf</title>
      </Helmet>

      <div className="background-wrapper">
        <img src={bubbles} alt="laundry detergent strips" className="rellax background1" data-rellax-speed="7" />
        <img src={hanger} alt="laundry detergent strips" className="rellax background2" data-rellax-speed="-4" />
        <img src={basket} alt="laundry detergent strips" className="rellax background4" data-rellax-speed="6" />
        <img src={kleanleaf} alt="laundry detergent strips" className="rellax background5" data-rellax-speed="8" />
      </div>

      <Container className="about-content">
        <Row style={{ textAlign: "center" }}>
          <h1>Laundry Detergent Sheets Manufacturer</h1>
          <h2 className="about-title center">
            About Us
            <p>Leading the laundry care industry</p>
          </h2>
        </Row>
        <Row className="hero-section">
          <Col md={7} className="hero-text">
            <h1>Trusted Industry Leader</h1>
            <p>
            With over 14 years of experience in the laundry care industry, we have established ourselves as a leading laundry detergent sheets factory, consistently delivering exceptional products and services. Our commitment to quality and customer satisfaction is reflected in our innovative formulations and reliable supply chain solutions, making us a trusted partner to our clients.
            </p>
            <div className="about-button"></div>
          </Col>
          <Col md={5} className="hero-image">
            <img className="feature-icon" src={claw} alt="laundry detergent strips supplier" />
          </Col>
        </Row>

        {/* Features Section with Accordion */}
        
        <Row className="features-section">
          <Col md={12} className="features-title"><h1>Frequently Asked Questions (FAQ)</h1></Col>
          <Col md={6} className="features-image">
            <img className="feature-team" src={boy} alt="laundry detergent strips supplier" />
          </Col>
          <Col md={6}>
            <Accordion activeKey={activeKey}>
              <Card>
                <Accordion.Header onClick={() => handleToggle("0")}>
                  <span>Q: What is the minimum order quantity (MOQ)?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p>
                      A: The MOQ is 150,000 sheets. For packs of 30 sheets, the MOQ is 5,000 units, and for packs of 50 sheets, the MOQ is 3,000 units.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Header onClick={() => handleToggle("1")}>
                  <span>Q: Where are Klean Leaf products manufactured?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>
                      A: Our products are manufactured in one of China’s top factories in Guangdong, ensuring consistent quality. For North American clients, our Columbus, Ohio office handles business operations, customer service, and logistics.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            
              <Card>
                <Accordion.Header onClick={() => handleToggle("2")}>
                  <span>Q:Are your products certified for quality and safety?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <p>
                      A: Yes, we hold certifications such as ISO 9001, ISO 22716 GMP, and OECD 301B. We also provide MSDS documentation for complete transparency on ingredients and safety standards.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Header onClick={() => handleToggle("3")}>
                  <span>Q: What are the available packaging options?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <p>
                      A: We offer standard packaging in biodegradable paper bags with 30 sheets, as well as a sturdy paper box option. Custom packaging designs are also available to suit your brand needs.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Header onClick={() => handleToggle("4")}>
                  <span>Q: Do you offer sample packs?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <p>
                      A: Yes, we provide sample packs that include a variety of scents. Reach out to us, and we can ship samples directly from our Columbus office, for free.


                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Header onClick={() => handleToggle("5")}>
                  <span>Q: What support do you offer for private label clients?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <p>
                      A: Our full-service support includes everything from manufacturing and packaging to logistics and direct delivery to your warehouse. We also assist with certifications, artwork integration, and creating customized packaging samples.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>
          </Col>
        </Row>

        <div className="integration-section">
          <Container>
            <Row>
              <Col>
                <h2>Certified for Quality and Safety</h2>
                <p>
                  Spanning 120,000 square meters, our facility adheres to FDA guidelines for safe and compliant production. We are proud to hold prestigious certifications such as ISO 9001, ISO 22716 GMP, OECD 301B, and MSDS, underscoring our unwavering commitment to quality and safety.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <Row className="hero-section onboarding-section">
          <Col md={7} className="hero-text">
            <h1>State-of-the-Art Manufacturing Facility✨</h1>
            <p>
            Located in Guangzhou, our advanced manufacturing plant specializes in production, research and development, and sales of top-tier laundry products. Our team of experts leverages the latest technologies to deliver consistent, high-quality results, continuously optimizing our processes for maximum efficiency.
            </p>
          </Col>
          <Col md={5} className="hero-image">
            <img className="feature-icon" src={stuffy} alt="Klean Leaf" />
          </Col>
        </Row>

        <Row className="my-5" style={{ justifyContent: "center" }}>
          <a
            className="pdf-link btn rounded-pill py-md-3 px-md-5 mt-4"
            href={pdf}
            style={{ maxWidth: "350px" }}
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineDownload />
            &nbsp;Download Klean Leaf Profile
          </a>
        </Row>

        <h2 className="about-heading">Unlock the Power of Klean Leaf</h2>
        <Techstack />
      </Container>
    </Container>
  );
}

export default About;
