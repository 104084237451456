import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Best Products",
          "Private Label Service",
          "Quality Assurance",
          "Global Reach",
          "Reliable Supply Chain",
          "Exceptional Customer Support",
          "Certified Manufacturing",
          "Cutting-Edge R&D"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 90,
      }}
    />
  );
}

export default Type;
