import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import packageSample from "../../Assets/marked-package.png";
import whiteSheets from "../../Assets/white-sheets.png";
import productImg from "../../Assets/sheet-with-basket.png";
import noMess from "../../Assets/no-mess.png";
import lightWeight from "../../Assets/light-weight.png";
import effectiveCleaning from "../../Assets/effective-cleaning.png";
import quickDissolve from "../../Assets/quick-dissolve.png";
import plasticFree from "../../Assets/plastic-free.png";
import Tilt from "react-parallax-tilt";
import { FiPhoneCall } from "react-icons/fi";

function Products() {
  return (
    <>
      <Container fluid className="products-section" id="products">
        <Container className="products-content">
          <Row>
            <h1 className="laundry-title">Laundry Detergent Sheets Manufacturer</h1>
            <h2 className="product-title">
              About Our Products
              <p>The Innovation</p>
            </h2>
          </Row>
          <Row>
            <Col md={6} style={{ paddingBottom: 20 }}>
              <Tilt>
                <img
                  src={packageSample}
                  alt="laundry detergent strips manufacturere"
                  className="img-fluid"
                />
              </Tilt>
            </Col>
            <Col md={5} className="products-intro">
              <p className="products-info">
                Our laundry detergent sheets are specially designed to dissolve
                completely during the wash, leaving no residue on your clothes.
                They're easy to use—just toss one into your washing machine's
                drum along with your garments. These sheets are also compact,
                making them a great choice for travelers, people with limited
                space, or those aiming to reduce packaging waste.
              </p>
              <p className="products-info">
                Our detergent sheets come in various scents, from fresh and
                clean to floral and fruity. You can pick the scent that best
                suits your style. Additionally, they're free from harsh
                chemicals, ensuring a gentle yet effective cleaning process for
                your clothes.
              </p>
              <p className="products-info mobile-no-display">
                Ingredients: Glycerol, Primary Alcohol Ethoxylate, Sodium Dodecyl Sulfate, Sodium Acetate Trihydrate, Protease, Polyvinyl alcohol (100% water-soluble), Deionized Water, Fragrance, Starch. <strong>You could add or remove any ingredient to meet your requirement. </strong>
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="products-content sheets-section">
          <Row>
            <Col md={6} style={{ paddingBottom: 20 }} className="sheets-img">
              <figure>
                <img
                  src={whiteSheets}
                  alt="laundry detergent strips manufacturer"
                  className="img-fluid"
                />
              </figure>
            </Col>
            <Col md={5} className="products-intro sheets-intro">
              <ul>
                <li>Eco-Friendly Compact</li>
                <li>Sustainable</li>
                <li>Hypoallergenic</li>
                <li>Efficient</li>
                <li>Minimal Waste</li>
                <li>Versatile</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>

      <div className="basket-wrapper">
        <div className="row mb-lg-0">
          <div className="col-lg-6 my-lg-5 product-text">
            <div className="product-start p-5">
              <p>
                We are committed to delivering top-notch, convenient, and
                efficient laundry solutions to our valued customers. Our
                standard laundry detergent sheets exemplify this dedication.
                These inventive sheets introduce a distinctive approach to
                garment care, simplifying and enhancing your laundry routine.
              </p>
              <a
                href="tel:614-906-5129"
                className="btn product-btn rounded-pill py-md-3 px-md-5 mt-4"
              >
                <FiPhoneCall />
                &nbsp;&nbsp;Call For Free Samples
              </a>
            </div>
          </div>

          <div className="col-lg-6 product-image-wrapper">
            <div className="position-relative h-100">
              <img
                src={productImg}
                className="product-image position-absolute w-100 h-100"
                style={{ objectFit: "cover" }}
                alt="laundry detergent strips manufacturer"
              />
            </div>
          </div>
        </div>
      </div>

      <Container
        fluid
        className="products-section products-details"
        id="products"
      >
        <Container className="products-content">
          <Row>
            <h2 className="products-details-title">Our detergent sheets</h2>
          </Row>

          <Row className="feature-wrapper">
            <Col xs={6} md={2} className="">
              <img src={noMess} className="feature-img" alt="laundry detergent strips manufacturer" />
            </Col>

            <Col xs={6} md={2} className="">
              <img
                src={lightWeight}
                className="feature-img"
                alt="laundry detergent strips manufacturer"
              />
            </Col>

            <Col xs={6} md={2} className="">
              <img
                src={effectiveCleaning}
                className="feature-img"
                alt="laundry detergent strips manufacturer"
              />
            </Col>

            <Col xs={6} md={2} className="">
              <img
                src={quickDissolve}
                className="feature-img"
                alt="laundry detergent strips manufacturer"
              />
            </Col>

            <Col xs={6} md={2} className="">
              <img
                src={plasticFree}
                className="feature-img"
                alt="laundry detergent strips manufacturer"
              />
            </Col>
          </Row>

          <Row className="products-details-text">
            <Col md={12} className="products-intro">
              <p className="products-info">
                Our detergent sheets represent a potent, ultra-concentrated
                solution that empowers us to address critical environmental
                concerns. By eliminating plastic usage, conserving water, and
                mitigating the transportation of heavy oil-laden products, we
                contribute significantly to a greener future while optimizing
                space and reducing waste.
              </p>

              <p className="products-info">
                We offer comprehensive private label solutions, too. Development
                process from design, formula development to shelf-ready
                packaging.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Products;
