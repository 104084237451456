"use client";
import React from "react";
import "./HomeVideo.scss";
import OceanVideo from "../../../Assets/ocean.mp4";
import OceanMobileVideo from "../../../Assets/ocean-mobile.mp4";
import { Container, Row, Col } from "react-bootstrap";
import Type from "../Type";

const HomeVideo = () => {
  return (
    <div className="video-wrapper">
      <div className="video-container">
        {/* Mobile Video */}
        <video
          className="full-width-video mobile"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={OceanMobileVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Desktop Video */}
        <video
          className="full-width-video desktop"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={OceanVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <Container fluid className="home-section" id="home">
          <Container className="home-content">
            <Row className="home-header">
              <Col md={10}>
                <h1 className="heading-name">
                  We are
                  <strong className="main-name"> Klean Leaf</strong>
                  <span className="wave" role="img" aria-labelledby="wave" style={{ paddingLeft: 15 }}>
                    👋🏻
                  </span>
                </h1>
                <h1 className="heading-subtitle">
                  Laundry Detergent Sheets Manufacturer
                </h1>

                <h2 className="heading-name we-offer">We Offer:</h2>
                <div className="typing-font">
                  <Type />
                </div>
              </Col>

              <Col md={5} style={{ paddingBottom: 80 }} />
            </Row>
          </Container>
        </Container>
      </div>
    </div>
  );
};

export default HomeVideo;
