import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mixing from "../../Assets/mixing.png";
import evaporation from "../../Assets/evaporation.png";
import cutting from "../../Assets/cutting.png";
import packaging from "../../Assets/packaging.png";
import productionFlow from "../../Assets/production-flow.png";
import oceanImg from "../../Assets/ocean.png";
import Tilt from "react-parallax-tilt";

function Production() {
  return (
    <Container className="production-section left">
      <Row style={{ textAlign: "center" }}>
        <h1>Laundry Detergent Sheets Manufacturer</h1>
        <h2 className="production-title center">
          Production
          <p>Manufacturing technologys</p>
        </h2>
      </Row>

      <Row>
        <Col md={12} className="production-flow-img-wrapper center">
          <Tilt>
            <img
              src={productionFlow}
              className="production-flow-img"
              alt="laundry detergent strips manufacturer"
            />
          </Tilt>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="my-4">
          <p className="production-flow-text left">
            At Klean Leaf, our unwavering commitment lies in the utilization of
            cutting-edge technology and the perpetual enhancement of our
            manufacturing processes. These efforts are dedicated to ensuring
            optimal efficiency and sustainability in the production of our
            detergent sheets. We ardently uphold a manufacturing approach that
            is not only environmentally conscientious but also consistently
            efficacious.
          </p>
        </Col>
      </Row>

      <Row className="column-reverse">
        <Col md={6} className="production-text">
          <h2>Mixing</h2>
          <p>
            The inception of our laundry detergent sheet manufacturing process
            commences with the meticulous blending of essential chemicals within
            a dedicated mixing tank. Our team of expert technicians conducts
            precise measurements and skillfully incorporates each ingredient
            into the tank, assiduously maintaining the use of correct
            proportions to fashion the most effective cleaning formulation.
          </p>
        </Col>
        <Col md={6} className="production-image">
          <Tilt>
            <img src={mixing} className="" alt="laundry detergent strips manufacturer" />
          </Tilt>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="production-image right">
          <Tilt>
            <img src={evaporation} className="" alt="laundry detergent strips manufacturer" />
          </Tilt>
        </Col>
        <Col md={6} className="production-text">
          <h2>Evaporation</h2>
          <p>
            The subsequent stage in the fabrication of our laundry detergent
            sheets involves the evaporation process, during which the liquid
            amalgam is subjected to elevated temperatures to eliminate any
            residual water content. This yields a desiccated detergent product,
            poised for subsequent processing.
          </p>
        </Col>
      </Row>

      <Row className="column-reverse">
        <Col md={6} className="production-text">
          <h2>Cutting</h2>
          <p>
            Following the successful culmination of the evaporation phase, the
            resultant dried detergent attains readiness for precision sizing.
            The sizeable pieces of dried detergent are then conveyed to a
            cutting apparatus, where they undergo meticulous trimming to achieve
            the exact dimensions requisite for our detergent sheets.
          </p>
        </Col>
        <Col md={6} className="production-image">
          <Tilt>
            <img src={cutting} className="" alt="laundry detergent strips manufacturer" />
          </Tilt>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="production-image right">
          <Tilt>
            <img src={packaging} className="" alt="laundry detergent strips manufacturer" />
          </Tilt>
        </Col>
        <Col md={6} className="production-text">
          <h2>Packaging</h2>
          <p>
            The ultimate phase in our manufacturing process involves the
            meticulous assembly of the precisely cut detergent sheets into the
            packaging of your choice. This critical task is entrusted to a
            specialized packaging machine, meticulously selected for its
            capability to deliver both efficiency and precision. With exacting
            care, the packaging machine orchestrates the arrangement of the cut
            detergent sheets into your preferred packaging boxes, ensuring that
            each box receives the precise quantity of sheets as specified.
          </p>
        </Col>
      </Row>

      <Row className="production-summary">
        <Col md={12} className="my-4">
          <img src={oceanImg} className="img-fluid" alt="laundry detergent strips manufacturer" />
        </Col>
        <Col>
          <p className="production-flow-text left">
            Our unwavering dedication to excellence and reliability enables us
            to guarantee the utmost quality in our products. Moreover, we hold a
            steadfast commitment to the integration of sustainable practices
            across every facet of our operations, ranging from the sourcing of
            raw materials to the final stages of product packaging. Our aim is
            to pave the way for a brighter future, both for our valued customers
            and the environment.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Production;
